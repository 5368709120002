const idPortalApi = "f713fd3fc4eb69807b2450d099b2ec4157e22aee";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmsenadoralexandrecosta.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsenadoralexandrecosta.ma.gov.br",
  NomePortal: "Sen. Alexandre Costa",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.youtube.com/@camaramunicipalsaodomingosdoma`,
  link_localize: `https://maps.app.goo.gl/JzwdewPngnzLDPdD9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15892.213848813348!2d-44.06230133266581!3d-5.254279860942225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x788c15049534883%3A0xdbe9bf24f04bae6b!2sSen.%20Alexandre%20Costa%2C%20MA%2C%2065783-000!5e0!3m2!1spt-BR!2sbr!4v1722016969629!5m2!1spt-BR!2sbr",
};
